
  export const style={
    inpuStyle:{
      colorInput:"#2E63FB"
    },
    url:{
      urlClient:"https://noz.prd.appnoz.com.br/",
      urlClientYouseed:"https://admyouseed.bancosemear.com.br/"
    },
    fileUrl:{
      logo:"https://timcoo-geral.s3.amazonaws.com/Logos/noz.png",
      secondaryLogonLogo: "null",
    },
    variables:{
       customMenuItem: null,
    },
    CurrencyName:{
      name:"Noz/Nozes"
    },
    teamName:{
      name:"NOZ"
    },
    hasToken: false,
    hostHeader: "noz"
  }
